import clsx from 'clsx';

import { IconKind } from '@shared/helpers';

import { Icon } from '../Icon';

import type { FC } from 'react';

export const SwitchIcon: FC<{
  opened?: boolean;
  className?: string;
  size?: number;
}> = ({ opened, size, className }) => (
  <Icon
    kind={IconKind.ARROW_DOWN}
    size={size}
    className={clsx(
      className,
      'transition-all ease-in-out duration-200',
      opened && 'rotate-180',
    )}
  />
);
