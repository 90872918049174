import { useLayoutEffect } from 'react';

import { useRouteError } from 'react-router-dom';

import { ErrorStub, PageSpinner } from '@shared/ui';

import { getErrorsCount, setErrorsCount } from '../lib/helpers';

import type { FC } from 'react';

export const RouterErrorElement: FC = () => {
  const routeError = String(useRouteError());
  const errorsCount = getErrorsCount();
  const isChunkErrorString = routeError.includes('chunk');
  const needReload = isChunkErrorString && errorsCount < 1;

  useLayoutEffect(() => {
    if (needReload) {
      setErrorsCount(getErrorsCount() + 1);
      window.location.reload();
    } else if (errorsCount > 0) {
      setErrorsCount(0);
    }
  }, [needReload, errorsCount]);

  if (needReload) return <PageSpinner />;

  return <ErrorStub />;
};
