import { useCallback, useState } from 'react';

import clsx from 'clsx';

import { IconKind } from '@shared/helpers';
import { IconButton } from '@shared/ui/IconButton';

import { NavigationList } from './features/NavigationList';
import { ProfileButton } from './features/ProfileButton';
import { SidebarLogo } from './features/SidebarLogo';
import {
  getPersistentCollapseToggle,
  persistCollapseToggle,
} from './lib/helpers';

import type { FC } from 'react';

export const NavigationSidebar: FC = () => {
  const [collapsed, setCollapsed] = useState(() =>
    getPersistentCollapseToggle(),
  );

  const toggleCollapse = useCallback(
    () =>
      setCollapsed((prev) => {
        persistCollapseToggle(!prev);

        return !prev;
      }),
    [],
  );

  return (
    <div
      className={clsx(
        'relative h-full bg-bg-default rounded-xl flex flex-col shrink-0 p-3 gap-3 transition-all overflow-x-hidden',
        collapsed ? 'w-16 items-start' : 'w-52',
      )}
    >
      <div className="w-full flex justify-end">
        <IconButton
          icon={IconKind.DOUBLE_ARROW_LEFT}
          onClick={toggleCollapse}
          className={clsx(
            'transition-all ease-in-out duration-200 mr-1.5',
            collapsed && 'rotate-180',
          )}
        />
      </div>
      <SidebarLogo collapsed={collapsed} />
      <nav className="h-full flex flex-col justify-between overflow-y-auto overflow-x-hidden">
        <NavigationList collapsed={collapsed} />
        <ProfileButton collapsed={collapsed} />
      </nav>
    </div>
  );
};
