import { LocalStorageKey } from '@shared/types';

export const persistCollapseToggle = (collapsed: boolean) =>
  localStorage.setItem(
    LocalStorageKey.SIDEBAR_COLLAPSE_TOGGLE,
    String(collapsed),
  );

export const getPersistentCollapseToggle = () =>
  localStorage.getItem(LocalStorageKey.SIDEBAR_COLLAPSE_TOGGLE) === 'true';
