import { memo, useMemo } from 'react';

import { clsx } from 'clsx';
import { NavLink } from 'react-router-dom';

import { useCurrentUser } from '@entities/user';
import { Icon } from '@shared/ui';

import { getNavRoutes } from './lib/routes';
import styles from './NavigationList.module.css';

const NavigationList = ({ collapsed }: { collapsed: boolean }) => {
  const { data } = useCurrentUser();

  const routes = useMemo(() => getNavRoutes(data?.role), [data?.role]);

  return (
    <>
      <ul className="flex flex-col gap-3">
        {routes.map(({ path, title, icon }) => (
          <li key={path} className="w-full">
            <NavLink
              to={path}
              className={({ isActive }) =>
                clsx(styles.item, { [styles.item_active]: isActive })
              }
            >
              <div className="min-w-6">{<Icon kind={icon} />}</div>

              {!collapsed && title}
            </NavLink>
          </li>
        ))}
      </ul>

      {/* <NavLink
        to={PAGES.SETTINGS.mask}
        className={({ isActive }) =>
          clsx(styles.item, { [styles.item_active]: isActive })
        }
      >
        <SettingIcon />

        {PAGES.SETTINGS.title}
      </NavLink> */}
    </>
  );
};

export default memo(NavigationList);
