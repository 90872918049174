import { Link } from 'react-router-dom';

import { ProSchoolLogo, ProSchoolLogoMini } from '@shared/assets';
import { PAGES } from '@shared/helpers';

import type { FC } from 'react';

export const SidebarLogo: FC<{ collapsed?: boolean }> = ({ collapsed }) => {
  if (collapsed) {
    return (
      <Link to={PAGES.SCHEDULE.mask} className="w-full flex justify-center">
        <img src={ProSchoolLogoMini} className="w-8 h-8" alt="logo" />
      </Link>
    );
  }

  return (
    <Link
      to={PAGES.SCHEDULE.mask}
      className="w-full py-4 flex justify-center rounded-lg bg-decorative-blue"
    >
      <img src={ProSchoolLogo} className="h-8" alt="logo" />
    </Link>
  );
};
