import { memo, useState } from 'react';

import clsx from 'clsx';

import { ROLE_NAMES, useCurrentUser } from '@entities/user';
import { UserAvatar } from '@features/UserAvatar';
import { getProfileName } from '@shared/helpers';
import { Button, Dropdown, Typography } from '@shared/ui';
import { SwitchIcon } from '@shared/ui/SwitchIcon';

import {
  DropdownItemId,
  ROLE_COLORS,
  PROFILE_DROPDOWN_ITEMS,
} from './config.tsx';
import { logout } from './helpers.ts';

import type { DropdownItemType } from '@shared/ui';
import type { FC } from 'react';

const ProfileButton: FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { data } = useCurrentUser();

  const [opened, setOpened] = useState(false);

  const handleSelect = (item: DropdownItemType<DropdownItemId> | null) => {
    if (item?.id === DropdownItemId.LOGOUT) {
      logout();
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Dropdown
      onOpenChange={setOpened}
      onChange={handleSelect}
      value={null}
      items={PROFILE_DROPDOWN_ITEMS}
      isAutoWidth={false}
      side={collapsed ? 'right' : 'top'}
      popoverClassName="w-52"
    >
      <Dropdown.Trigger asChild>
        <Button
          variant="blank"
          className={'p-0 gap-0 justify-start text-text-default'}
          rightElement={
            !collapsed ? (
              <SwitchIcon className={clsx(!opened && 'rotate-[-90deg]')} />
            ) : null
          }
        >
          <UserAvatar src="" />

          {!collapsed && (
            <div className="flex flex-col items-start min-w-20 ml-2">
              <Typography
                variant="caption1"
                className="truncate w-full text-left"
              >
                {getProfileName(data)}
              </Typography>
              {data.role && (
                <span className={clsx(ROLE_COLORS[data.role], 'text-xs')}>
                  {ROLE_NAMES[data.role]}
                </span>
              )}
            </div>
          )}
        </Button>
      </Dropdown.Trigger>
    </Dropdown>
  );
};

export default memo(ProfileButton);
